<template>
    <div>
        <Quote title="Bu yerda siz foydalanuvchi ma'lumotlarini o'zgartirishingiz mumkin"/>
        <GoBack next="O'zgartirish"/>

        <div class="row justify-content-center">
            <div class="col-8">
                <form @submit.prevent="editUser" class="ABC">
                    <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">Email</label>
                        <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="E-mail"
                            v-model="forms.email" required
                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputGivenName1" class="form-label">Ism</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputGivenName1"
                            placeholder="Ism"
                            v-model="forms.givenName"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleInputFamilyName1" class="form-label">Familiya</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputFamilyName1"
                            placeholder="Familiya"
                            v-model="forms.familyName"
                            required
                        />
                    </div>
                    <label for="exampleInputFamilyName1" class="form-label">Telefon raqam</label>
                    <vue-tel-input
                        class="tel"
                        id="exampleInputFamilyName1"
                        :inputOptions="options"
                        v-model="forms.phoneNumber"
                    >
                    </vue-tel-input>

                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Telegram login</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputId1"
                            placeholder="Telegram raqam"
                            v-model="forms.telegramNumber"
                            required
                        />
                    </div>
                    <div class="row justify-content-between mb-3">
                        <div class="col-12 col-md-4">
                            <input
                                id="check"
                                class="me-2"
                                v-if="getOneUser.isTeacher"
                                @input="isTeacher(getOneUser.id)"
                                type="checkbox"
                                checked
                            >
                            <input
                                class="me-2"
                                id="check"
                                v-else @input="isTeacher(getOneUser.id)"
                                type="checkbox"
                            >
                            <label for="check" class="form-label">O'qituvchi</label>
                        </div>
                        <div class="text-start text-md-center my-3 my-md-0 col-12 col-md-4">
                            <router-link :to="{name: 'User-courses', params: {user: this.$route.params.id}}" style="text-decoration: none">
                                <h6>Kurslari &#129122;</h6>
                            </router-link>
                        </div>

                        <div class="text-start text-md-end col-12 col-md-4">
                            <router-link :to="'/add-student/' + $route.params.id" style="text-decoration: none">
                                <h6>Kursga qo'shish &plus;</h6>
                            </router-link>
                        </div>
                    </div>

                    <div class="row justify-content-between mb-3">
                        <div class="d-grid gap-2 col-12 col-md-5  mb-3 mb-md-0">
                            <button class="btn btn-primary" type="submit">O'zgartirish</button>
                        </div>
                        <div class="d-grid gap-2 col-12 col-md-5">
                            <button
                                class="btn btn-danger"
                                type="button"
                                @click="showModal(forms.id)"
                            >
                                O'chirish
                            </button>
                        </div>
                    </div>

                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ushbu foydalanuvchi o'chirildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                    <div>
                        <b-modal ref="modal-delete" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ushbu foydalanuvchini rostdan ham o'chirmoqchimisiz?</h3>
                            </div>
                            <b-button
                                class="mt-3"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                Yo'q
                            </b-button>
                            <b-button
                                class="mt-3 float-end p-2"
                                style="width: 100px"
                                variant="btn btn-danger"
                                size="md"
                                @click="deleteUser"
                            >
                                Ha
                            </b-button>
                        </b-modal>
                    </div>

                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ushbu foydalanuvchi o'chirildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>

                    <div>
                        <b-modal ref="modal-unsuccess" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ma'lumotlarni o'zgartirishda xatolik yuz berdi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="errorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                    <div>
                        <b-modal ref="modal-edit-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Foydalanuvchi ma'lumotlarini muvaffaqiyatli o'zgartirdingiz!</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "RegistrationPage",
    components: {GoBack, Quote},
    data() {
        return {
            forms: {
                id: null,
                email: "",
                givenName: "",
                familyName: "",
                phoneNumber: "",
                telegramNumber: "",
                isTeacher: false,
                roles: ["ROLE_USER"]
            },
            options: {placeholder: "Bog'lanish uchun telefon"}
        }
    },
    computed: {
        ...mapGetters(['getOneUser'])
    },
    methods: {
        ...mapActions(['fetchOneUser', 'putUserInfo', 'deleteOneUser', 'teacherUser']),
        editUser() {
            if (
                this.forms.email !== this.getOneUser.email ||
                this.forms.givenName !== this.getOneUser.givenName ||
                this.forms.familyName !== this.getOneUser.familyName ||
                this.forms.phoneNumber !== this.getOneUser.phoneNumber ||
                this.forms.telegramNumber !== this.getOneUser.telegramNumber
            ) {
                this.putUserInfo({
                    id: this.forms.id,
                    email: this.forms.email,
                    givenName: this.forms.givenName,
                    familyName: this.forms.familyName,
                    phoneNumber: this.forms.phoneNumber,
                    telegramNumber: this.forms.telegramNumber,
                })
                    .then(() => {
                        this.$refs['modal-edit-success'].show()
                        setTimeout(this.hideErrorModal, 5000)
                    })
                    .catch(() => {
                        this.$refs['modal-unsuccess'].show()
                        setTimeout(this.errorModal, 5000)
                        console.log('registration da xato')
                    })
            } else {
                this.$router.push('/cabinet')
                setTimeout(this.errorModal, 5000)
            }
        },
        deleteUser() {
            this.deleteOneUser(this.userId)
                .then(() => {
                    this.$refs['modal-delete'].hide()
                    setTimeout(this.hideErrorModal, 3000)
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                    this.fetchUsers(this.currentPage)
                })
        },
        isTeacher(id) {
            this.teacherUser(id)
        },
        showModal(id) {
            this.userId = id
            this.$refs['modal-delete'].show()
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$refs['modal-delete'].hide()
            this.$refs['modal-edit-success'].hide()
            this.$router.push('/cabinet')
        },
        errorModal() {
            this.$refs['modal-unsuccess'].hide()
        }
    },
    mounted() {
        this.show = true
        console.log(5555)
        this.fetchOneUser(this.$route.params.id)
            .then(() => {
                this.forms.id = this.getOneUser.id
                this.forms.email = this.getOneUser.email
                this.forms.givenName = this.getOneUser.givenName
                this.forms.familyName = this.getOneUser.familyName
                this.forms.phoneNumber = this.getOneUser.phoneNumber
                this.forms.telegramNumber = this.getOneUser.telegramNumber
            })
        this.forms.show = false
    }
}
</script>

<style scoped>
* {
    padding: 0;
    margin: 0;
}

section form {
    font-family: 'Comfortaa', cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px !important;
    border: solid #80007F 1px !important;
    font-family: Comfortaa, cursive !important;
}

.tel {
    width: 100% !important;
    height: 38px !important;
    margin-bottom: 10px;
    border-radius: 10px !important;
    border: solid #80007F 1px !important;
    font-family: Comfortaa, cursive !important;
    padding-right: 15px !important;
    padding-left: 12px !important;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;
}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>
